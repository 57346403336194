
import React from 'react';
import PropTypes from 'prop-types';
import {omit} from 'ramda';


/**
 * Video is a wrapper for the <video> HTML5 element.
 * For detailed attribute info see:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video
 */
const Video = ({n_clicks = 0, n_clicks_timestamp = -1, ...props}) => {
    const extraProps = {};
    const ctx = window.dash_component_api.useDashContext();
    const loading = ctx.useLoading();
    if (loading) {
        extraProps['data-dash-is-loading'] = true;
    }

     /* remove unnecessary onClick event listeners  */
    const isStatic = props.disable_n_clicks || !props.id;
    return (
        <video
            {...(!isStatic && {onClick:
            () => props.setProps({
                n_clicks: n_clicks + 1,
                n_clicks_timestamp: Date.now()
            })
            })}
            {...omit(['n_clicks', 'n_clicks_timestamp', 'loading_state', 'setProps', 'disable_n_clicks'], props)}
            {...extraProps}
        >
            {props.children}
        </video>
    );
};


Video.propTypes = {
    /**
     * The ID of this component, used to identify dash components
     * in callbacks. The ID needs to be unique across all of the
     * components in an app.
     */
    'id': PropTypes.string,

    /**
     * The children of this component
     */
    'children': PropTypes.node,

    /**
     * An integer that represents the number of times
     * that this element has been clicked on.
     */
    'n_clicks': PropTypes.number,

    /**
     * An integer that represents the time (in ms since 1970)
     * at which n_clicks changed. This can be used to tell
     * which button was changed most recently.
     */
    'n_clicks_timestamp': PropTypes.number,

    /**
     * When True, this will disable the n_clicks prop.  Use this to remove
     * event listeners that may interfere with screen readers.
     */
    'disable_n_clicks': PropTypes.bool,

    /**
     * A unique identifier for the component, used to improve
     * performance by React.js while rendering components
     * See https://reactjs.org/docs/lists-and-keys.html for more info
     */
    'key': PropTypes.string,

    /**
     * A wildcard data attribute
     */
    'data-*': PropTypes.string,

    /**
     * A wildcard aria attribute
     */
    'aria-*': PropTypes.string,

    /**
     * The audio or video should play as soon as possible.
     */
    'autoPlay': PropTypes.oneOfType([
        PropTypes.oneOf(['autoPlay', 'autoplay', 'AUTOPLAY']),
        PropTypes.bool
     ]),

    /**
     * Indicates whether the browser should show playback controls to the user.
     */
    'controls': PropTypes.oneOfType([
        PropTypes.oneOf(['controls', 'CONTROLS']),
        PropTypes.bool
     ]),

    /**
     * How the element handles cross-origin requests
     */
    'crossOrigin': PropTypes.string,

    /**
     * Specifies the height of elements listed here. For all other elements, use the CSS height property. Note: In some instances, such as <div>, this is a legacy attribute, in which case the CSS height property should be used instead.
     */
    'height': PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
     ]),

    /**
     * Indicates whether the media should start playing from the start when it's finished.
     */
    'loop': PropTypes.oneOfType([
        PropTypes.oneOf(['loop', 'LOOP']),
        PropTypes.bool
     ]),

    /**
     * Indicates whether the audio will be initially silenced on page load.
     */
    'muted': PropTypes.oneOfType([
        PropTypes.oneOf(['muted', 'MUTED']),
        PropTypes.bool
     ]),

    /**
     * A URL indicating a poster frame to show until the user plays or seeks.
     */
    'poster': PropTypes.string,

    /**
     * Indicates whether the whole resource, parts of it or nothing should be preloaded.
     */
    'preload': PropTypes.string,

    /**
     * The URL of the embeddable content.
     */
    'src': PropTypes.string,

    /**
     * For the elements listed here, this establishes the element's width. Note: For all other instances, such as <div>, this is a legacy attribute, in which case the CSS width property should be used instead.
     */
    'width': PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
     ]),

    /**
     * Keyboard shortcut to activate or add focus to the element.
     */
    'accessKey': PropTypes.string,

    /**
     * Often used with CSS to style elements with common properties.
     */
    'className': PropTypes.string,

    /**
     * Indicates whether the element's content is editable.
     */
    'contentEditable': PropTypes.string,

    /**
     * Defines the text direction. Allowed values are ltr (Left-To-Right) or rtl (Right-To-Left)
     */
    'dir': PropTypes.string,

    /**
     * Defines whether the element can be dragged.
     */
    'draggable': PropTypes.string,

    /**
     * Prevents rendering of given element, while keeping child elements, e.g. script elements, active.
     */
    'hidden': PropTypes.oneOfType([
        PropTypes.oneOf(['hidden', 'HIDDEN']),
        PropTypes.bool
     ]),

    /**
     * Defines the language used in the element.
     */
    'lang': PropTypes.string,

    /**
     * Defines an explicit role for an element for use by assistive technologies.
     */
    'role': PropTypes.string,

    /**
     * Indicates whether spell checking is allowed for the element.
     */
    'spellCheck': PropTypes.string,

    /**
     * Defines CSS styles which will override styles previously set.
     */
    'style': PropTypes.object,

    /**
     * Overrides the browser's default tab order and follows the one specified instead.
     */
    'tabIndex': PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
     ]),

    /**
     * Text to be displayed in a tooltip when hovering over the element.
     */
    'title': PropTypes.string,

    /**
     * Dash-assigned callback that gets fired when the element is clicked.
     */
    'setProps': PropTypes.func
};

export default Video;
